* {
  background-color: #000000;
  color: white;
}

nav.navbar-fixed-top {
  position: fixed;
  top: 0;
}

body::before {
  display: block;
  content: "";
  height: 60px; /* or whatever */
  background-color: #000000;
}

.coverPage {
  padding-top: 5%;
  padding-bottom: 15%;
  padding-left: 3%;
  padding-right: 3%;
}

.avatar {
  vertical-align: middle;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.stefanImg {
  border-radius: 50%;
}

.logo {
  border-radius: 50%;
}

.title {
  padding-left: 2%;
  padding-top: 2%;
}

.about {
  padding-top: 3%;
  padding-bottom: 4%;
}

.aboutContent {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 10%;
}

textarea {
  width: 600px;
  height: 150px;
}

::placeholder {
  text-align: center;
}

.rowC {
  display: flex;
  flex-direction: row;
  padding-left: 20%;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 10%;
  color: black;
}

.serviceBox {
  padding-top: 11%;
  padding-bottom: 30%;
  padding-left: 15%;
  padding-right: 15%;
}

.box {
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 20%;
  padding-right: 20%;
  text-align: center;
}

.divider__arrow {
  font-size: 5rem;
  font-weight: 400;
  margin-top: 5%;
  margin-bottom: 15%;

}



@media screen and (max-width: 580px) {
  .mobile {
    display: none;
  }

  .rowC {
    display: flex;
    flex-direction: row;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 10%;
    color: black;
  }

  .request {
    width: 100%;
  }

  body {
    width: 100%;
  }

  .aboutContent{
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .mobileSpace {
    padding-top: 20%;
    padding-bottom: 15%;
    text-align: center;
  }
}
